html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

#menuItem {
    font-family: 'Roboto', sans-serif;
}


